import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useObjectId } from "components/pages/Documents/hooks/useObjectId";

import { IEstimateTargetWithType } from "../SpecificationAddRelationsModal/SpecificationAddRelationsModal";
import Spinner from "shared/ui/atoms/Spinner/Spinner";
import ButtonBase from "shared/ui/controls/ButtonBase";
import BlueLabel from "shared/ui/dataDisplay/BlueLabel/BlueLabel";
import TableReusableHead, { TableReusableHeaderCell } from "shared/ui/dataDisplay/TableReusable/TableReusableHead";
import Checkbox, { LabelPlacement } from "shared/ui/inputs/Checkbox/Checkbox";
import InputBase, { INPUT_BASE_VARIANTS, VALUE_TYPES } from "shared/ui/inputs/InputBase";
import RowGrid from "shared/ui/layout/RowGrid/RowGrid";
import Modal from "shared/ui/modal/Modal";

import { errorCatcher } from "utils/helpers/errorCatcher";

import styles from "./SpecificationSplitModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  expenditureId?: number;
  itemId: number;
  selectedRdTarget: IEstimateTargetWithType[];
}

const colsWidth = [23, 10, 10, 22, 10, 10, 15];

const SpecificationSplitModal = ({ isOpen, onClose, itemId, expenditureId, selectedRdTarget }: IProps) => {
  const objectId = useObjectId();
  const [material, setMaterial] = useState<Material>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isOpen) return;
    setIsLoading(true);
    axios
      .get(`/building/${objectId}/materials/specification/${itemId}/ `)
      .then(({ data }) => {
        setMaterial(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [objectId, itemId, isOpen]);

  const [targetCount, setTargetCount] = useState<Record<string, string>>({});
  const [isAllCount, setIsAllCount] = useState(false);
  const [isEqualCount, setIsEqualCount] = useState(false);

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsAllCount(true);
      setIsEqualCount(false);
      setTargetCount(
        Object.fromEntries(
          selectedRdTarget.map((el) => [el.expenditureId, Math.floor(+material?.count! / selectedRdTarget.length)])
        )
      );
    } else {
      setIsAllCount(false);
      setTargetCount({});
    }
  };

  const onCheckEqual = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsEqualCount(true);
      setIsAllCount(false);
      let restToAdd = +material?.count!;
      setTargetCount(
        Object.fromEntries(
          selectedRdTarget.map((el) => {
            let diff = Math.min(restToAdd, +el.data.count);
            restToAdd -= diff;
            return [el.expenditureId, diff];
          })
        )
      );
    } else {
      setIsEqualCount(false);
      setTargetCount({});
    }
  };

  const onInput = (expId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllCount(false);
    setIsEqualCount(false);
    setTargetCount((prev) => ({ ...prev, [expId]: e.target.value }));
  };

  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const onSubmit = () => {
    setIsPending(true);
    axios
      .patch(
        `/building/${objectId}/materials/specification/${itemId}/bulk_update/`,
        Object.entries(targetCount).map(([estimate_expenditure_id, count]) => ({
          estimate_expenditure_id,
          count,
        }))
      )
      .then(() => {
        dispatch({ type: "specification/INVALIDATE_KEY" });
        onClose();
      })
      .catch(errorCatcher)
      .finally(() => {
        setIsPending(false);
      });
  };

  const restCount = +(material?.count ?? 0) - Object.values(targetCount).reduce<number>((acc, cur) => acc + +cur, 0);

  const isSubmitDisabled =
    isLoading || Object.values(targetCount).some((el) => +el <= 0 || isNaN(+el)) || restCount < 0;

  return (
    <Modal title="Ресурсы для связи" isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <div className={styles.content}>
        {isLoading && (
          <div className={styles.spinner}>
            <Spinner isStatic />
          </div>
        )}
        {!!material && (
          <div className={styles.table}>
            <TableReusableHead isSmall className={styles.head}>
              <RowGrid percents={colsWidth}>
                <TableReusableHeaderCell>Наименование</TableReusableHeaderCell>
                <TableReusableHeaderCell isCentered>Ед. изм.</TableReusableHeaderCell>
                <TableReusableHeaderCell isCentered>Доступно</TableReusableHeaderCell>
                <TableReusableHeaderCell>Работа</TableReusableHeaderCell>
                <TableReusableHeaderCell isCentered>Ед. изм.</TableReusableHeaderCell>
                <TableReusableHeaderCell isCentered>Кол-во</TableReusableHeaderCell>
                <TableReusableHeaderCell isCentered>Связать</TableReusableHeaderCell>
              </RowGrid>
            </TableReusableHead>
            <div className={styles.list}>
              {selectedRdTarget?.map((el) => (
                <Fragment>
                  <div className={styles.row}>
                    <RowGrid percents={colsWidth}>
                      <div className={styles.nameCell}>
                        <div>{material.name}</div>
                      </div>
                      <div className={styles.centered}>{material.measure}</div>
                      <div className={styles.centered}>{restCount}</div>
                      <div className={styles.nameCell}>
                        <div>{el.data?.name}</div>
                      </div>
                      <div className={styles.centered}>{el.data?.measure}</div>
                      <div className={styles.centered}>{el.data?.count}</div>
                      <div>
                        <InputBase
                          variant={INPUT_BASE_VARIANTS.SECONDARY}
                          valueType={VALUE_TYPES.NUMBER}
                          value={targetCount[el.expenditureId!]}
                          onChange={onInput(el.expenditureId!)}
                          meta={getInputMeta(el.expenditureId!, material, targetCount)}
                        />
                      </div>
                    </RowGrid>
                  </div>
                  {!material.estimate_expenditure?.justification && (
                    <BlueLabel className={styles.hz}>{material.estimate_expenditure?.justification}</BlueLabel>
                  )}
                </Fragment>
              ))}
            </div>
            <div className={styles.linkAll}>
              <RowGrid percents={colsWidth}>
                <div />
                <div />
                <div />
                <Checkbox
                  checked={isEqualCount}
                  onCheck={onCheckEqual}
                  labelPlacement={LabelPlacement.RIGHT}
                  className={styles.equalCheckbox}
                >
                  Связать в соответствии с кол-вом работ
                </Checkbox>
                <Checkbox checked={isAllCount} onCheck={onCheck} labelPlacement={LabelPlacement.RIGHT}>
                  Связать все
                </Checkbox>
              </RowGrid>
            </div>
          </div>
        )}
        <div className={styles.bottom}>
          <ButtonBase primary disabled={isSubmitDisabled} onClick={onSubmit} isLoading={isPending}>
            Сохранить
          </ButtonBase>
        </div>
      </div>
    </Modal>
  );
};

export default SpecificationSplitModal;

type Material = {
  id: number;
  number: string;
  name: string;
  count: string;
  price: string;
  measure: string;
  brand: string;
  producer: string;
  is_consumable: false;
  created_from_estimate: true;
  estimate_expenditure: {
    justification?: "";
  };
};

function getInputMeta(expenditureId: number, material: any, targetCount: Record<string, string>) {
  const restCount =
    +(material?.count ?? 0) -
    Object.values(targetCount).reduce<number>((acc, cur) => acc + +cur, 0) +
    +(targetCount[expenditureId] || 0);

  const isError = +(targetCount[expenditureId] || 0) > restCount || isNaN(+(targetCount[expenditureId] || 0));
  return {
    touched: true,
    error: isError && "Недопустимое значение",
  };
}
